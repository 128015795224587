import React from "react"
import styled from "styled-components"
import ButtonLink from "../../../ButtonLink/ButtonLink"
import BeginCards from "./BeginCards"
import BlocksIcon from "../../../../images/kolumna-bg.png"
import investorsIcon from "../../../../images/dla-biznesu.svg"
import useWindowSize from "../../../../utils/UseWindowSize"

const WhyStyles = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 1118px) {
    background: url(${BlocksIcon});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 70vh;
    background-size: cover;
    margin-top: 60px;
  }
  @media only screen and (max-width: 640px) {
    margin-top: 40px;
    background-position-x: 8%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  position: relative;
  z-index: 2;
  justify-content: center;
  padding: 20% 167px 20%;
  @media (max-width: 1440px) {
    padding: 15% 167px 15%;
    max-width: 100%;
  }
  @media (max-width: 1260px) {
    padding: 10% 103px 15%;
  }
  @media (max-width: 1118px) {
    padding: 50% 60px 20%;
  }
  @media (max-width: 960px) {
    padding: 55% 60px 20%;
  }
  @media only screen and (max-width: 801px) {
    padding: 65% 55px 20%;
  }
  @media only screen and (max-width: 721px) {
    padding: 620px 55px 160px;
  }
  @media only screen and (max-width: 640px) {
    padding: 460px 55px 150px;
  }
  @media only screen and (max-width: 440px) {
    padding: 520px 55px 150px;
  }
  @media only screen and (max-width: 380px) {
    padding: 520px 30px 150px;
  }
  text-align: center;

  > img {
    position: absolute;
    left: 0;
    top: 0;
  }

  h2 {
    font-size: 10px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--primary);
  }

  h3 {
    font-size: 48px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--headers-color);
    margin-top: 10px;
    @media only screen and (max-width: 1118px) {
      font-size: 40px;
    }
    @media only screen and (max-width: 640px) {
      margin-top: 6px;
      font-size: 24px;
    }
  }

  p {
    max-width: 767px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: var(--body-text);
    @media only screen and (max-width: 801px) {
      max-width: unset;
    }
  }
`

const IconStyles = styled.img`
  position: absolute;
  width: 100%;
  object-fit: contain;
  top: -50%;
  @media only screen and (max-width: 2000px) {
    top: -40%;
  }
  @media only screen and (max-width: 1500px) {
    top: -40%;
  }
`

const SvgIconStyles = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  #k1,
  #k2,
  #k3 {
    fill: red;
    transform: scale(3);
  }
`

const InvestorsIcon = () => (
  <SvgIconStyles
    viewBox="0 0 1440 1511"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Kolumna i shape ">
      <path
        id="Rectangle"
        d="M1440 636.251L24.1244 350.453C-46.7006 336.156 -115.241 385.736 -131.914 463.323L-357 1511L1160 1333.56C1213.92 1327.26 1259.24 1285.94 1275.21 1228.54L1440 636.251Z"
        fill="white"
      />
      <g id="Asset 2 4" clip-path="url(#clip0)">
        <g id="&#226;&#128;&#148;&#195;&#142;&#195;&#147;&#195;&#136;_1">
          <g id="Group" opacity="0.5">
            <path
              id="Vector"
              style={{ mixBlendMode: "lighten" }}
              opacity="0.4"
              d="M4.6675 252.81L-101.973 303.967L110.157 404.435L216.769 353.278L4.6675 252.81Z"
              fill="url(#paint0_linear)"
            />
            <path
              id="Vector_2"
              style={{ mixBlendMode: "lighten" }}
              opacity="0.4"
              d="M430.388 252.417L537 303.551L324.899 404.02L218.258 352.886L430.388 252.417Z"
              fill="url(#paint1_linear)"
            />
          </g>
          <path
            id="Vector_3"
            d="M216.742 455.362L107.883 403.72L216.742 352.102L325.6 403.72L216.742 455.362Z"
            fill="#312A91"
          />
          <path
            id="Vector_4"
            d="M216.742 440.508L107.883 388.867L216.742 337.248L325.6 388.867L216.742 440.508Z"
            fill="#312A91"
          />
          <path
            id="Vector_5"
            style={{ mixBlendMode: "lighten" }}
            opacity="0.7"
            d="M216.742 428.722L132.704 388.867L216.742 349.011L300.78 388.867L216.742 428.722Z"
            fill="url(#paint2_linear)"
          />
          <path
            id="Vector_6"
            d="M325.6 388.867V403.72L216.742 455.361V440.508L325.6 388.867Z"
            fill="url(#paint3_linear)"
          />
          <path
            id="Vector_7"
            d="M107.883 388.867V403.72L216.742 455.361V440.508L107.883 388.867Z"
            fill="#312A91"
          />
          <path
            id="Vector_8"
            style={{ mixBlendMode: "lighten" }}
            opacity="0.7"
            d="M107.883 388.867V403.72L216.742 455.361V440.508L107.883 388.867Z"
            fill="url(#paint4_linear)"
          />
          <path
            id="Vector_9"
            d="M216.742 233.251L107.883 181.633L216.742 130.014L325.6 181.633L216.742 233.251Z"
            fill="#312A91"
          />
          <path
            id="Vector_10"
            d="M216.742 218.397L107.883 166.779L216.742 115.161L325.6 166.779L216.742 218.397Z"
            fill="#312A91"
          />
          <path
            id="Vector_11"
            style={{ mixBlendMode: "lighten" }}
            opacity="0.7"
            d="M216.742 218.397L107.883 166.779L216.742 115.161L325.6 166.779L216.742 218.397Z"
            fill="url(#paint5_linear)"
          />
          <path
            id="Vector_12"
            opacity="0.3"
            d="M216.742 185.9L176.45 166.779L216.742 147.659L257.034 166.779L216.742 185.9Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_13"
            opacity="0.3"
            d="M216.742 160.644L243.36 173.26L257.034 166.779L216.742 147.659L176.45 166.779L190.124 173.26L216.742 160.644Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_14"
            d="M216.742 160.644L243.36 173.26L257.034 166.779L216.742 147.659L176.45 166.779L190.124 173.26L216.742 160.644Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_15"
            d="M325.6 166.779V181.633L216.742 233.251V218.397L325.6 166.779Z"
            fill="url(#paint6_linear)"
          />
          <path
            id="Vector_16"
            d="M107.883 166.779V181.633L216.742 233.251V218.397L107.883 166.779Z"
            fill="#312A91"
          />
          <path
            id="Vector_17"
            style={{ mixBlendMode: "lighten" }}
            opacity="0.7"
            d="M107.883 166.779V181.633L216.742 233.251V218.397L107.883 166.779Z"
            fill="url(#paint7_linear)"
          />
          <path
            id="Vector_18"
            opacity="0.3"
            d="M216.742 233.251V440.508L107.883 388.867V181.633L216.742 233.251Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_19"
            opacity="0.3"
            d="M132.704 193.396V388.867L216.742 428.722L300.78 388.867V193.396L216.742 233.251L132.704 193.396Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_20"
            opacity="0.3"
            d="M216.742 233.251V428.722L132.704 388.867V193.396L216.742 233.251Z"
            fill="#42E8E0"
          />
          <g id="Group_2" opacity="0.7">
            <path
              id="Vector_21"
              opacity="0.7"
              d="M216.742 348.804L132.704 308.925L216.742 269.07L300.78 308.925L216.742 348.804Z"
              fill="url(#paint8_linear)"
            />
            <path
              id="Vector_22"
              opacity="0.7"
              d="M300.78 308.925V388.867L216.742 428.722V348.804L300.78 308.925Z"
              fill="url(#paint9_linear)"
            />
            <path
              id="Vector_23"
              opacity="0.7"
              d="M132.704 308.925V388.867L216.742 428.722V348.804L132.704 308.925Z"
              fill="url(#paint10_linear)"
            />
          </g>
          <path
            id="Vector_24"
            opacity="0.5"
            d="M107.883 181.633V388.867L216.742 440.508L325.6 388.867V181.633L216.742 233.251L107.883 181.633Z"
            fill="#5CFFF3"
          />
          <path
            id="Vector_25"
            style={{ mixBlendMode: "lighten" }}
            d="M110.466 166.779L218.034 115.761L216.742 115.161L107.883 166.779L216.742 218.397L218.034 217.798L110.466 166.779Z"
            fill="url(#paint11_linear)"
          />
          <path
            id="Vector_26"
            opacity="0.5"
            d="M107.883 181.633V388.867L109.371 389.582V182.348L107.883 181.633Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_27"
            opacity="0.5"
            d="M325.601 181.633V388.867L324.084 389.582V182.348L325.601 181.633Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_28"
            opacity="0.5"
            d="M216.742 233.251L214.159 232.028V439.309L216.742 440.508L219.325 439.309V232.028L216.742 233.251Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_29"
            style={{ mixBlendMode: "lighten" }}
            opacity="0.1"
            d="M257.034 166.779L315.998 0H117.486L176.45 166.779L216.742 185.9L257.034 166.779Z"
            fill="url(#paint12_linear)"
          />
          <path
            id="Vector_30"
            style={{ mixBlendMode: "lighten" }}
            opacity="0.1"
            d="M257.034 166.779L291.036 0H140.061L176.45 166.779L216.742 185.9L257.034 166.779Z"
            fill="url(#paint13_linear)"
          />
          <path
            id="Vector_31"
            opacity="0.3"
            d="M186.699 123.879C186.699 124.203 186.582 124.52 186.363 124.789C186.143 125.058 185.832 125.268 185.468 125.392C185.104 125.516 184.703 125.548 184.316 125.485C183.929 125.422 183.574 125.266 183.295 125.037C183.017 124.808 182.827 124.516 182.75 124.199C182.673 123.881 182.712 123.552 182.863 123.252C183.014 122.953 183.27 122.697 183.597 122.518C183.925 122.338 184.311 122.242 184.705 122.242C185.234 122.242 185.741 122.414 186.115 122.721C186.488 123.028 186.699 123.445 186.699 123.879V123.879Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_32"
            opacity="0.2"
            d="M200.653 105.335C200.653 105.776 200.44 106.198 200.061 106.51C199.682 106.821 199.168 106.996 198.632 106.996C198.093 106.996 197.576 106.822 197.192 106.511C196.809 106.2 196.589 105.778 196.582 105.335C196.582 104.889 196.798 104.461 197.182 104.145C197.567 103.829 198.088 103.652 198.632 103.652C199.17 103.658 199.685 103.838 200.063 104.153C200.441 104.468 200.653 104.893 200.653 105.335V105.335Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_33"
            opacity="0.2"
            d="M258.269 100.307C258.983 100.307 259.561 99.8322 259.561 99.2463C259.561 98.6603 258.983 98.1853 258.269 98.1853C257.556 98.1853 256.978 98.6603 256.978 99.2463C256.978 99.8322 257.556 100.307 258.269 100.307Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_34"
            opacity="0.2"
            d="M256.248 93.7339C256.248 94.1901 256.083 94.636 255.775 95.0153C255.466 95.3946 255.028 95.6902 254.514 95.8648C254.001 96.0394 253.437 96.085 252.892 95.9961C252.347 95.9071 251.847 95.6874 251.454 95.3648C251.062 95.0423 250.794 94.6313 250.686 94.1839C250.578 93.7365 250.633 93.2727 250.846 92.8513C251.058 92.4298 251.418 92.0696 251.88 91.8162C252.342 91.5628 252.885 91.4275 253.44 91.4275C254.185 91.4275 254.899 91.6705 255.425 92.103C255.952 92.5356 256.248 93.1222 256.248 93.7339Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_35"
            opacity="0.2"
            d="M193.1 103.052C193.814 103.052 194.392 102.577 194.392 101.991C194.392 101.405 193.814 100.93 193.1 100.93C192.387 100.93 191.809 101.405 191.809 101.991C191.809 102.577 192.387 103.052 193.1 103.052Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_36"
            opacity="0.3"
            d="M190.826 155.454C191.927 155.454 192.82 154.721 192.82 153.817C192.82 152.912 191.927 152.179 190.826 152.179C189.725 152.179 188.833 152.912 188.833 153.817C188.833 154.721 189.725 155.454 190.826 155.454Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_37"
            opacity="0.2"
            d="M206.859 135.273C206.859 135.716 206.647 136.14 206.268 136.456C205.89 136.771 205.376 136.951 204.837 136.957C204.293 136.957 203.772 136.779 203.388 136.464C203.003 136.148 202.787 135.72 202.787 135.273C202.795 134.831 203.014 134.408 203.398 134.097C203.781 133.787 204.298 133.612 204.837 133.612C205.373 133.612 205.887 133.787 206.267 134.099C206.646 134.41 206.859 134.833 206.859 135.273V135.273Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_38"
            opacity="0.2"
            d="M199.221 132.99C199.935 132.99 200.513 132.515 200.513 131.929C200.513 131.343 199.935 130.868 199.221 130.868C198.508 130.868 197.93 131.343 197.93 131.929C197.93 132.515 198.508 132.99 199.221 132.99Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_39"
            opacity="0.3"
            d="M248.554 92.8113C248.686 93.1696 248.981 93.4723 249.377 93.6565C249.774 93.8407 250.242 93.892 250.684 93.7998C251.125 93.7077 251.506 93.4791 251.748 93.1617C251.989 92.8444 252.072 92.4627 251.98 92.0963C251.931 91.9041 251.836 91.7221 251.699 91.5611C251.563 91.4 251.388 91.2634 251.186 91.1594C250.983 91.0554 250.757 90.9862 250.521 90.9559C250.285 90.9256 250.044 90.9349 249.812 90.9832C249.581 91.0316 249.364 91.1179 249.174 91.2371C248.984 91.3562 248.826 91.5057 248.708 91.6765C248.59 91.8474 248.516 92.036 248.49 92.231C248.463 92.426 248.485 92.6234 248.554 92.8113V92.8113Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_40"
            opacity="0.2"
            d="M231.567 79.3649C231.653 79.6549 231.842 79.9173 232.11 80.1186C232.378 80.3198 232.713 80.4508 233.073 80.4947C233.432 80.5385 233.799 80.4933 234.127 80.3648C234.455 80.2363 234.729 80.0304 234.913 79.7733C235.098 79.5163 235.185 79.2199 235.163 78.922C235.141 78.6241 235.012 78.3384 234.791 78.1013C234.57 77.8642 234.269 77.6866 233.924 77.5912C233.58 77.4958 233.209 77.487 232.859 77.5658C232.63 77.6142 232.415 77.6991 232.227 77.8157C232.038 77.9324 231.879 78.0784 231.759 78.2455C231.639 78.4125 231.56 78.5974 231.528 78.7895C231.495 78.9815 231.508 79.1771 231.567 79.3649Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_41"
            opacity="0.2"
            d="M179.258 84.7619C179.28 84.8937 179.336 85.0202 179.421 85.1332C179.507 85.2461 179.621 85.3429 179.755 85.4173C179.889 85.4916 180.041 85.5419 180.201 85.5647C180.36 85.5876 180.524 85.5825 180.681 85.5499C180.838 85.5172 180.985 85.4577 181.112 85.3752C181.239 85.2928 181.343 85.1892 181.418 85.0713C181.493 84.9534 181.537 84.8238 181.547 84.691C181.557 84.5582 181.533 84.4252 181.476 84.3006C181.376 84.0829 181.183 83.9029 180.932 83.7951C180.681 83.6874 180.39 83.6593 180.116 83.7164C179.841 83.7735 179.602 83.9117 179.444 84.1046C179.285 84.2975 179.219 84.5315 179.258 84.7619V84.7619Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_42"
            opacity="0.2"
            d="M180.662 79.4109C180.808 79.9039 181.185 80.3298 181.71 80.5972C182.235 80.8646 182.867 80.9521 183.469 80.8409C183.774 80.7773 184.06 80.6651 184.311 80.5107C184.562 80.3563 184.774 80.1628 184.935 79.9411C185.095 79.7194 185.201 79.474 185.246 79.2188C185.29 78.9636 185.274 78.7036 185.196 78.4537C185.119 78.2039 184.982 77.969 184.794 77.7624C184.606 77.5559 184.371 77.3818 184.101 77.2501C183.831 77.1184 183.532 77.0316 183.221 76.9948C182.911 76.9579 182.594 76.9717 182.29 77.0353C181.692 77.1751 181.186 77.5031 180.881 77.948C180.576 78.3928 180.497 78.9186 180.662 79.4109Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_43"
            opacity="0.2"
            d="M236.06 75.3515C236.134 75.5962 236.324 75.8066 236.587 75.9363C236.85 76.0661 237.166 76.1046 237.464 76.0434C237.761 75.9823 238.018 75.8264 238.175 75.6101C238.333 75.3939 238.38 75.1349 238.306 74.8902C238.226 74.648 238.036 74.4406 237.774 74.3115C237.512 74.1825 237.2 74.1419 236.902 74.1983C236.754 74.2279 236.615 74.2814 236.492 74.3556C236.37 74.4298 236.266 74.5232 236.188 74.6304C236.109 74.7377 236.058 74.8567 236.036 74.9804C236.014 75.1042 236.022 75.2303 236.06 75.3515V75.3515Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_44"
            opacity="0.2"
            d="M249.958 154.855C249.764 154.991 249.525 155.077 249.272 155.101C249.019 155.126 248.762 155.088 248.534 154.993C248.307 154.898 248.119 154.75 247.994 154.567C247.869 154.384 247.813 154.175 247.832 153.966C247.852 153.757 247.946 153.558 248.104 153.393C248.261 153.228 248.475 153.105 248.717 153.04C248.96 152.974 249.22 152.969 249.466 153.026C249.712 153.082 249.932 153.197 250.099 153.355C250.21 153.461 250.294 153.584 250.347 153.717C250.4 153.85 250.421 153.99 250.408 154.129C250.395 154.268 250.348 154.404 250.271 154.529C250.194 154.653 250.088 154.764 249.958 154.855V154.855Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_45"
            opacity="0.2"
            d="M252.934 149.204C252.518 149.516 251.997 149.718 251.439 149.783C250.881 149.848 250.312 149.774 249.806 149.57C249.3 149.366 248.88 149.042 248.602 148.64C248.323 148.237 248.199 147.775 248.244 147.314C248.289 146.852 248.503 146.413 248.857 146.052C249.21 145.692 249.688 145.427 250.227 145.293C250.766 145.158 251.343 145.16 251.881 145.298C252.418 145.436 252.893 145.704 253.243 146.067C253.685 146.525 253.899 147.103 253.841 147.686C253.784 148.268 253.46 148.81 252.934 149.204Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_46"
            opacity="0.3"
            d="M248.807 143.784C248.606 144.018 248.493 144.297 248.484 144.584C248.475 144.871 248.57 145.154 248.756 145.397C248.942 145.639 249.212 145.832 249.531 145.949C249.851 146.066 250.205 146.102 250.55 146.054C250.894 146.006 251.214 145.875 251.468 145.677C251.722 145.48 251.899 145.226 251.977 144.946C252.055 144.666 252.03 144.373 251.906 144.105C251.781 143.836 251.563 143.604 251.278 143.438C251.088 143.327 250.874 143.248 250.647 143.205C250.42 143.163 250.185 143.157 249.955 143.189C249.726 143.221 249.506 143.29 249.309 143.392C249.112 143.494 248.941 143.627 248.807 143.784V143.784Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_47"
            opacity="0.2"
            d="M250.295 124.548C250.139 124.853 250.111 125.193 250.218 125.512C250.324 125.831 250.558 126.112 250.882 126.309C251.206 126.506 251.601 126.607 252.004 126.598C252.407 126.588 252.794 126.467 253.103 126.255C253.243 126.098 253.342 125.92 253.397 125.731C253.451 125.543 253.459 125.347 253.42 125.156C253.38 124.964 253.295 124.781 253.168 124.618C253.041 124.454 252.876 124.313 252.682 124.202C252.303 124.005 251.848 123.931 251.407 123.995C250.965 124.059 250.569 124.256 250.295 124.548V124.548Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_48"
            opacity="0.2"
            d="M256.922 124.663C256.746 124.872 256.677 125.129 256.729 125.38C256.782 125.63 256.952 125.853 257.202 126.001C257.326 126.074 257.466 126.126 257.614 126.154C257.762 126.183 257.915 126.187 258.065 126.166C258.215 126.146 258.358 126.102 258.487 126.036C258.616 125.97 258.728 125.884 258.817 125.782C258.905 125.681 258.969 125.566 259.003 125.444C259.038 125.323 259.043 125.197 259.018 125.074C258.993 124.95 258.939 124.833 258.859 124.727C258.779 124.621 258.674 124.529 258.55 124.456C258.427 124.38 258.287 124.325 258.138 124.295C257.989 124.264 257.834 124.258 257.683 124.278C257.531 124.297 257.385 124.341 257.255 124.407C257.124 124.474 257.011 124.561 256.922 124.663V124.663Z"
            fill="#42E8E0"
          />
          <g id="Group_3" opacity="0.9">
            <path
              id="Vector_49"
              opacity="0.9"
              d="M210.256 171.807C210.402 172.243 210.701 172.634 211.115 172.931C211.529 173.229 212.041 173.42 212.586 173.48C213.131 173.541 213.686 173.469 214.182 173.273C214.678 173.076 215.092 172.765 215.374 172.377C215.656 171.989 215.793 171.541 215.768 171.09C215.743 170.638 215.557 170.203 215.233 169.837C214.909 169.472 214.461 169.193 213.946 169.034C213.431 168.876 212.87 168.846 212.334 168.947C211.965 169.017 211.616 169.147 211.31 169.33C211.004 169.514 210.746 169.746 210.552 170.013C210.358 170.28 210.232 170.577 210.181 170.885C210.13 171.193 210.155 171.507 210.256 171.807Z"
              fill="#42E8E0"
            />
            <path
              id="Vector_50"
              opacity="0.9"
              d="M218.567 159.145C218.633 159.357 218.75 159.556 218.91 159.731C219.07 159.906 219.271 160.053 219.5 160.165C219.73 160.276 219.984 160.349 220.247 160.38C220.511 160.411 220.78 160.399 221.038 160.344C221.296 160.29 221.539 160.194 221.752 160.062C221.965 159.931 222.144 159.766 222.28 159.578C222.415 159.389 222.504 159.181 222.542 158.964C222.579 158.747 222.564 158.526 222.498 158.314C222.364 157.886 222.028 157.519 221.565 157.294C221.102 157.069 220.548 157.005 220.027 157.115C219.506 157.225 219.059 157.501 218.785 157.881C218.512 158.262 218.433 158.716 218.567 159.145V159.145Z"
              fill="#42E8E0"
            />
          </g>
          <path
            id="Vector_51"
            opacity="0.3"
            d="M186.699 93.734C186.699 94.0579 186.582 94.3745 186.363 94.6438C186.143 94.9131 185.832 95.123 185.468 95.2469C185.104 95.3709 184.703 95.4033 184.316 95.3401C183.929 95.2769 183.574 95.121 183.295 94.8919C183.017 94.6629 182.827 94.3711 182.75 94.0535C182.673 93.7358 182.712 93.4066 182.863 93.1073C183.014 92.8081 183.27 92.5524 183.597 92.3724C183.925 92.1925 184.311 92.0964 184.705 92.0964C185.234 92.0964 185.741 92.269 186.115 92.5761C186.488 92.8832 186.699 93.2997 186.699 93.734V93.734Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_52"
            opacity="0.2"
            d="M200.653 75.1671C200.653 75.6097 200.441 76.0345 200.063 76.3496C199.685 76.6647 199.17 76.8448 198.632 76.8508C198.088 76.8508 197.567 76.6734 197.182 76.3577C196.798 76.0419 196.582 75.6137 196.582 75.1671C196.589 74.7246 196.809 74.3022 197.192 73.9914C197.576 73.6806 198.093 73.5064 198.632 73.5065C199.168 73.5065 199.682 73.6814 200.061 73.9929C200.44 74.3043 200.653 74.7267 200.653 75.1671Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_53"
            opacity="0.2"
            d="M259.561 69.1934C259.561 69.4033 259.485 69.6084 259.343 69.7829C259.201 69.9573 259 70.0933 258.764 70.1736C258.528 70.2539 258.268 70.2749 258.017 70.234C257.767 70.1931 257.537 70.092 257.356 69.9436C257.175 69.7953 257.052 69.6062 257.003 69.4004C256.953 69.1946 256.978 68.9813 257.076 68.7874C257.174 68.5935 257.339 68.4278 257.552 68.3113C257.764 68.1947 258.014 68.1325 258.269 68.1325C258.44 68.1293 258.61 68.1546 258.768 68.2068C258.927 68.259 259.07 68.337 259.191 68.4362C259.312 68.5353 259.407 68.6536 259.47 68.7837C259.534 68.9139 259.565 69.0532 259.561 69.1934V69.1934Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_54"
            opacity="0.2"
            d="M256.248 63.5887C256.248 64.0448 256.083 64.4908 255.775 64.8701C255.466 65.2494 255.028 65.545 254.514 65.7196C254.001 65.8941 253.437 65.9398 252.892 65.8508C252.347 65.7618 251.847 65.5421 251.454 65.2196C251.062 64.897 250.794 64.486 250.686 64.0386C250.578 63.5912 250.633 63.1275 250.846 62.706C251.058 62.2846 251.418 61.9244 251.88 61.6709C252.342 61.4175 252.885 61.2822 253.44 61.2822C254.185 61.2822 254.899 61.5252 255.425 61.9578C255.952 62.3903 256.248 62.977 256.248 63.5887Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_55"
            opacity="0.2"
            d="M193.1 72.9067C193.814 72.9067 194.392 72.4317 194.392 71.8458C194.392 71.2598 193.814 70.7848 193.1 70.7848C192.387 70.7848 191.809 71.2598 191.809 71.8458C191.809 72.4317 192.387 72.9067 193.1 72.9067Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_56"
            opacity="0.3"
            d="M192.82 123.672C192.82 123.996 192.703 124.312 192.484 124.581C192.265 124.851 191.953 125.061 191.589 125.185C191.225 125.309 190.824 125.341 190.437 125.278C190.05 125.215 189.695 125.059 189.416 124.83C189.138 124.601 188.948 124.309 188.871 123.991C188.794 123.673 188.833 123.344 188.984 123.045C189.135 122.746 189.391 122.49 189.719 122.31C190.046 122.13 190.432 122.034 190.826 122.034C191.355 122.034 191.862 122.207 192.236 122.514C192.61 122.821 192.82 123.237 192.82 123.672V123.672Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_57"
            opacity="0.2"
            d="M204.753 106.788C205.869 106.788 206.774 106.045 206.774 105.128C206.774 104.211 205.869 103.467 204.753 103.467C203.636 103.467 202.731 104.211 202.731 105.128C202.731 106.045 203.636 106.788 204.753 106.788Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_58"
            opacity="0.2"
            d="M199.221 102.844C199.935 102.844 200.513 102.369 200.513 101.784C200.513 101.198 199.935 100.723 199.221 100.723C198.508 100.723 197.93 101.198 197.93 101.784C197.93 102.369 198.508 102.844 199.221 102.844Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_59"
            opacity="0.3"
            d="M248.554 62.6662C248.613 62.8509 248.716 63.0243 248.857 63.1765C248.997 63.3286 249.173 63.4565 249.374 63.5529C249.574 63.6493 249.796 63.7122 250.027 63.7382C250.257 63.7641 250.491 63.7525 250.716 63.7041C250.941 63.6556 251.152 63.5712 251.338 63.4557C251.523 63.3403 251.679 63.1959 251.796 63.031C251.913 62.8661 251.99 62.6838 252.021 62.4946C252.053 62.3054 252.039 62.1129 251.98 61.9281C251.921 61.7432 251.819 61.5697 251.678 61.4175C251.537 61.2652 251.362 61.1372 251.161 61.0408C250.96 60.9444 250.738 60.8815 250.508 60.8556C250.277 60.8298 250.043 60.8415 249.818 60.8902C249.591 60.9357 249.377 61.0182 249.19 61.133C249.003 61.2477 248.845 61.3922 248.727 61.5579C248.609 61.7237 248.533 61.9072 248.504 62.0976C248.474 62.288 248.491 62.4814 248.554 62.6662Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_60"
            opacity="0.2"
            d="M231.567 49.2195C231.653 49.5095 231.842 49.7719 232.11 49.9732C232.378 50.1745 232.713 50.3054 233.073 50.3493C233.432 50.3932 233.799 50.3479 234.127 50.2194C234.455 50.0909 234.729 49.885 234.913 49.628C235.098 49.3709 235.185 49.0745 235.163 48.7766C235.141 48.4788 235.012 48.193 234.791 47.9559C234.57 47.7189 234.269 47.5413 233.924 47.4459C233.58 47.3505 233.209 47.3416 232.859 47.4204C232.63 47.4688 232.415 47.5537 232.227 47.6704C232.038 47.787 231.879 47.933 231.759 48.1001C231.639 48.2672 231.56 48.452 231.528 48.6441C231.495 48.8362 231.508 49.0317 231.567 49.2195V49.2195Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_61"
            opacity="0.2"
            d="M179.258 54.6166C179.311 54.8 179.429 54.9662 179.598 55.0938C179.767 55.2215 179.978 55.3048 180.205 55.3331C180.432 55.3614 180.664 55.3333 180.871 55.2526C181.078 55.1718 181.252 55.042 181.368 54.8798C181.485 54.7176 181.54 54.5304 181.527 54.3423C181.513 54.1541 181.432 53.9736 181.292 53.8239C181.153 53.6742 180.962 53.562 180.745 53.5019C180.528 53.4418 180.293 53.4364 180.072 53.4865C179.784 53.5516 179.539 53.7057 179.387 53.9165C179.235 54.1273 179.189 54.3783 179.258 54.6166Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_62"
            opacity="0.2"
            d="M180.662 49.2658C180.815 49.7556 181.193 50.1779 181.716 50.4444C182.239 50.7109 182.867 50.8009 183.47 50.6958C184.062 50.565 184.572 50.2538 184.895 49.8254C185.219 49.397 185.331 48.8835 185.211 48.3894C185.051 47.9038 184.671 47.487 184.149 47.2252C183.626 46.9633 183.001 46.8764 182.403 46.9824C181.809 47.1031 181.295 47.4088 180.97 47.8349C180.645 48.2609 180.535 48.7741 180.662 49.2658Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_63"
            opacity="0.2"
            d="M236.06 45.2063C236.116 45.387 236.236 45.5501 236.405 45.6748C236.574 45.7996 236.784 45.8806 237.009 45.9075C237.233 45.9344 237.463 45.9062 237.668 45.8262C237.873 45.7463 238.045 45.6183 238.162 45.4583C238.279 45.2983 238.336 45.1135 238.325 44.9271C238.314 44.7408 238.236 44.5612 238.102 44.4109C237.967 44.2607 237.781 44.1466 237.568 44.0829C237.354 44.0192 237.123 44.0088 236.902 44.0531C236.754 44.0827 236.615 44.1362 236.492 44.2104C236.37 44.2846 236.266 44.378 236.188 44.4852C236.109 44.5925 236.058 44.7115 236.036 44.8352C236.014 44.959 236.022 45.0851 236.06 45.2063V45.2063Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_64"
            opacity="0.2"
            d="M249.959 124.686C249.768 124.818 249.534 124.9 249.287 124.923C249.039 124.946 248.789 124.908 248.568 124.815C248.347 124.721 248.164 124.576 248.042 124.397C247.921 124.219 247.867 124.015 247.886 123.811C247.906 123.607 247.998 123.412 248.151 123.251C248.305 123.09 248.513 122.97 248.749 122.906C248.986 122.842 249.24 122.837 249.48 122.891C249.72 122.945 249.935 123.056 250.099 123.21C250.209 123.314 250.293 123.435 250.346 123.566C250.398 123.697 250.419 123.835 250.405 123.973C250.392 124.11 250.346 124.244 250.269 124.366C250.193 124.489 250.087 124.598 249.959 124.686V124.686Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_65"
            opacity="0.2"
            d="M252.934 119.036C252.381 119.234 251.765 119.281 251.177 119.17C250.588 119.058 250.06 118.794 249.669 118.416C249.278 118.038 249.046 117.566 249.007 117.071C248.968 116.576 249.124 116.084 249.453 115.668C249.691 115.366 250.012 115.115 250.389 114.933C250.767 114.751 251.191 114.644 251.628 114.621C252.065 114.598 252.502 114.659 252.905 114.799C253.308 114.94 253.666 115.155 253.949 115.429C254.233 115.704 254.434 116.028 254.537 116.378C254.641 116.727 254.643 117.092 254.545 117.442C254.446 117.792 254.249 118.119 253.97 118.396C253.69 118.672 253.336 118.891 252.934 119.036V119.036Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_66"
            opacity="0.3"
            d="M248.807 113.639C248.606 113.873 248.493 114.152 248.484 114.439C248.475 114.726 248.57 115.009 248.756 115.251C248.942 115.494 249.212 115.686 249.531 115.803C249.851 115.92 250.205 115.957 250.55 115.909C250.894 115.861 251.214 115.73 251.468 115.532C251.722 115.335 251.899 115.08 251.977 114.8C252.055 114.521 252.03 114.228 251.906 113.96C251.781 113.691 251.563 113.459 251.278 113.293C251.088 113.182 250.874 113.103 250.647 113.06C250.42 113.017 250.185 113.012 249.955 113.044C249.726 113.076 249.506 113.145 249.309 113.247C249.112 113.349 248.941 113.482 248.807 113.639V113.639Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_67"
            opacity="0.2"
            d="M250.295 94.3105C250.096 94.5505 249.987 94.8336 249.982 95.1242C249.978 95.4149 250.078 95.7002 250.27 95.9444C250.462 96.1885 250.737 96.3807 251.062 96.4967C251.386 96.6127 251.745 96.6475 252.093 96.5966C252.442 96.5457 252.764 96.4115 253.02 96.2107C253.276 96.0099 253.454 95.7515 253.532 95.468C253.61 95.1844 253.584 94.8882 253.458 94.6167C253.331 94.3451 253.11 94.1102 252.822 93.9414C252.427 93.7173 251.94 93.6303 251.466 93.6994C250.993 93.7685 250.572 93.9881 250.295 94.3105Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_68"
            opacity="0.2"
            d="M256.922 94.5642C256.746 94.773 256.677 95.0301 256.729 95.2803C256.782 95.5304 256.952 95.7537 257.202 95.902C257.326 95.9747 257.466 96.0267 257.614 96.055C257.762 96.0833 257.915 96.0874 258.065 96.0671C258.215 96.0467 258.358 96.0023 258.487 95.9364C258.616 95.8705 258.728 95.7843 258.817 95.6829C258.905 95.5814 258.969 95.4666 259.003 95.345C259.038 95.2235 259.043 95.0975 259.018 94.9744C258.993 94.8512 258.939 94.7333 258.859 94.6273C258.779 94.5213 258.674 94.4293 258.55 94.3567C258.427 94.2809 258.287 94.2262 258.138 94.1956C257.989 94.165 257.834 94.1592 257.683 94.1785C257.531 94.1979 257.385 94.242 257.255 94.3082C257.124 94.3744 257.011 94.4615 256.922 94.5642V94.5642Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_69"
            opacity="0.3"
            d="M186.699 63.5887C186.699 63.9126 186.582 64.2292 186.363 64.4985C186.143 64.7678 185.832 64.9777 185.468 65.1017C185.104 65.2256 184.703 65.258 184.316 65.1949C183.929 65.1317 183.574 64.9757 183.295 64.7467C183.017 64.5177 182.827 64.2259 182.75 63.9082C182.673 63.5906 182.712 63.2613 182.863 62.9621C183.014 62.6628 183.27 62.4071 183.597 62.2272C183.925 62.0472 184.311 61.9512 184.705 61.9512C185.234 61.9512 185.741 62.1237 186.115 62.4308C186.488 62.7379 186.699 63.1544 186.699 63.5887V63.5887Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_70"
            opacity="0.2"
            d="M200.653 45.0218C200.653 45.4644 200.441 45.8892 200.063 46.2043C199.685 46.5194 199.17 46.6995 198.632 46.7056C198.088 46.7056 197.567 46.5282 197.182 46.2124C196.798 45.8967 196.582 45.4684 196.582 45.0218C196.589 44.5793 196.809 44.157 197.192 43.8462C197.576 43.5354 198.093 43.3612 198.632 43.3612C199.168 43.3612 199.682 43.5362 200.061 43.8476C200.44 44.159 200.653 44.5814 200.653 45.0218Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_71"
            opacity="0.2"
            d="M258.269 40.0168C258.983 40.0168 259.561 39.5418 259.561 38.9559C259.561 38.3699 258.983 37.8949 258.269 37.8949C257.556 37.8949 256.978 38.3699 256.978 38.9559C256.978 39.5418 257.556 40.0168 258.269 40.0168Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_72"
            opacity="0.2"
            d="M256.248 33.4205C256.248 33.8766 256.083 34.3226 255.775 34.7019C255.466 35.0811 255.028 35.3768 254.514 35.5513C254.001 35.7259 253.437 35.7716 252.892 35.6826C252.347 35.5936 251.847 35.3739 251.454 35.0514C251.062 34.7288 250.794 34.3178 250.686 33.8704C250.578 33.423 250.633 32.9593 250.846 32.5378C251.058 32.1164 251.418 31.7562 251.88 31.5027C252.342 31.2493 252.885 31.114 253.44 31.114C254.185 31.114 254.899 31.357 255.425 31.7896C255.952 32.2221 256.248 32.8088 256.248 33.4205Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_73"
            opacity="0.2"
            d="M193.1 42.7384C193.814 42.7384 194.392 42.2634 194.392 41.6774C194.392 41.0915 193.814 40.6165 193.1 40.6165C192.387 40.6165 191.809 41.0915 191.809 41.6774C191.809 42.2634 192.387 42.7384 193.1 42.7384Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_74"
            opacity="0.3"
            d="M192.82 93.5264C192.82 93.8503 192.703 94.1669 192.484 94.4362C192.265 94.7055 191.953 94.9154 191.589 95.0393C191.225 95.1632 190.824 95.1957 190.437 95.1325C190.05 95.0693 189.695 94.9133 189.416 94.6843C189.138 94.4553 188.948 94.1635 188.871 93.8458C188.794 93.5282 188.833 93.1989 188.984 92.8997C189.135 92.6005 189.391 92.3447 189.719 92.1648C190.046 91.9848 190.432 91.8888 190.826 91.8888C191.355 91.8888 191.862 92.0613 192.236 92.3684C192.61 92.6755 192.82 93.0921 192.82 93.5264V93.5264Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_75"
            opacity="0.2"
            d="M206.859 74.9825C206.859 75.423 206.646 75.8453 206.267 76.1568C205.887 76.4682 205.373 76.6432 204.837 76.6432C204.298 76.6432 203.781 76.469 203.398 76.1582C203.014 75.8474 202.795 75.4251 202.787 74.9825C202.787 74.536 203.003 74.1077 203.388 73.792C203.772 73.4762 204.293 73.2988 204.837 73.2988C205.376 73.3049 205.89 73.485 206.268 73.8001C206.647 74.1152 206.859 74.5399 206.859 74.9825V74.9825Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_76"
            opacity="0.2"
            d="M199.221 72.6992C199.935 72.6992 200.513 72.2242 200.513 71.6382C200.513 71.0523 199.935 70.5773 199.221 70.5773C198.508 70.5773 197.93 71.0523 197.93 71.6382C197.93 72.2242 198.508 72.6992 199.221 72.6992Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_77"
            opacity="0.3"
            d="M248.555 32.4977C248.607 32.6838 248.704 32.8593 248.841 33.0139C248.977 33.1685 249.15 33.2992 249.349 33.3981C249.548 33.4969 249.769 33.5621 249.999 33.5897C250.229 33.6173 250.463 33.6067 250.688 33.5587C250.922 33.5188 251.144 33.4404 251.34 33.3283C251.536 33.2161 251.702 33.0725 251.829 32.9061C251.956 32.7397 252.04 32.554 252.077 32.3601C252.114 32.1663 252.102 31.9683 252.043 31.7781C251.985 31.5879 251.879 31.4095 251.734 31.2536C251.589 31.0977 251.407 30.9675 251.199 30.871C250.991 30.7744 250.762 30.7134 250.524 30.6918C250.287 30.6701 250.047 30.6881 249.818 30.7448C249.594 30.7905 249.383 30.8723 249.198 30.9855C249.013 31.0987 248.857 31.2409 248.74 31.4041C248.622 31.5672 248.545 31.7479 248.513 31.9356C248.482 32.1234 248.496 32.3144 248.555 32.4977V32.4977Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_78"
            opacity="0.2"
            d="M231.567 19.0743C231.653 19.3643 231.842 19.6268 232.11 19.828C232.378 20.0293 232.713 20.1603 233.073 20.2041C233.432 20.248 233.799 20.2028 234.127 20.0743C234.455 19.9458 234.729 19.7398 234.913 19.4828C235.098 19.2258 235.185 18.9293 235.163 18.6315C235.141 18.3336 235.012 18.0479 234.791 17.8108C234.57 17.5737 234.269 17.3961 233.924 17.3007C233.58 17.2053 233.209 17.1965 232.859 17.2753C232.628 17.3209 232.411 17.4041 232.22 17.52C232.03 17.6359 231.869 17.7821 231.749 17.9499C231.628 18.1178 231.55 18.3038 231.519 18.497C231.488 18.6901 231.504 18.8865 231.567 19.0743V19.0743Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_79"
            opacity="0.2"
            d="M179.258 24.4714C179.357 24.6891 179.551 24.8691 179.802 24.9769C180.053 25.0847 180.344 25.1127 180.618 25.0556C180.893 24.9985 181.132 24.8603 181.29 24.6674C181.448 24.4746 181.515 24.2405 181.476 24.0101C181.454 23.8783 181.398 23.7518 181.313 23.6388C181.227 23.5259 181.113 23.4291 180.979 23.3547C180.845 23.2804 180.693 23.2301 180.533 23.2073C180.374 23.1844 180.21 23.1895 180.053 23.2222C179.896 23.2548 179.749 23.3143 179.622 23.3968C179.495 23.4792 179.39 23.5828 179.315 23.7007C179.24 23.8186 179.196 23.9482 179.186 24.081C179.177 24.2138 179.201 24.3468 179.258 24.4714V24.4714Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_80"
            opacity="0.2"
            d="M180.662 19.1204C180.815 19.6102 181.193 20.0325 181.716 20.299C182.239 20.5655 182.868 20.6556 183.47 20.5504C183.802 20.5083 184.12 20.4095 184.403 20.2605C184.687 20.1115 184.929 19.9156 185.115 19.6852C185.3 19.4549 185.425 19.1952 185.482 18.9228C185.538 18.6505 185.525 18.3714 185.442 18.1036C185.36 17.8357 185.21 17.585 185.003 17.3675C184.795 17.1499 184.535 16.9703 184.238 16.8402C183.942 16.7101 183.615 16.6324 183.28 16.612C182.944 16.5916 182.607 16.629 182.29 16.7217C181.691 16.8666 181.184 17.1991 180.879 17.6477C180.575 18.0962 180.497 18.6251 180.662 19.1204V19.1204Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_81"
            opacity="0.2"
            d="M236.06 15.061C236.116 15.2418 236.236 15.4048 236.405 15.5296C236.574 15.6543 236.784 15.7353 237.009 15.7622C237.233 15.7892 237.463 15.7609 237.668 15.681C237.873 15.601 238.045 15.473 238.162 15.313C238.279 15.153 238.336 14.9682 238.325 14.7819C238.314 14.5955 238.236 14.4159 238.102 14.2657C237.967 14.1155 237.781 14.0013 237.568 13.9376C237.354 13.874 237.123 13.8636 236.902 13.9078C236.754 13.9375 236.615 13.9909 236.492 14.0651C236.37 14.1393 236.266 14.2327 236.188 14.34C236.109 14.4472 236.058 14.5662 236.036 14.69C236.014 14.8137 236.022 14.9399 236.06 15.061V15.061Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_82"
            opacity="0.2"
            d="M249.958 94.5643C249.832 94.6548 249.685 94.7237 249.525 94.7669C249.366 94.8101 249.198 94.8269 249.03 94.8162C248.863 94.8054 248.7 94.7674 248.551 94.7044C248.402 94.6413 248.269 94.5545 248.161 94.449C248.05 94.3431 247.966 94.2203 247.913 94.0875C247.86 93.9548 247.839 93.8147 247.852 93.6754C247.865 93.5361 247.912 93.4003 247.989 93.2758C248.066 93.1513 248.172 93.0405 248.302 92.9498C248.566 92.8009 248.888 92.7381 249.206 92.7734C249.523 92.8087 249.813 92.9398 250.02 93.141C250.226 93.3422 250.334 93.5994 250.323 93.8624C250.312 94.1254 250.182 94.3756 249.958 94.5643Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_83"
            opacity="0.2"
            d="M252.934 88.8903C252.381 89.0891 251.765 89.1361 251.177 89.0245C250.588 88.9128 250.06 88.6483 249.669 88.2703C249.278 87.8923 249.046 87.4209 249.007 86.926C248.968 86.4311 249.124 85.9391 249.453 85.5229C249.691 85.2212 250.012 84.9693 250.389 84.7874C250.767 84.6056 251.191 84.4988 251.628 84.4756C252.065 84.4524 252.502 84.5135 252.905 84.6539C253.308 84.7943 253.666 85.0101 253.949 85.2842C254.233 85.5583 254.434 85.883 254.537 86.2324C254.641 86.5819 254.643 86.9463 254.545 87.2967C254.446 87.647 254.249 87.9736 253.97 88.2503C253.69 88.527 253.336 88.7462 252.934 88.8903Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_84"
            opacity="0.3"
            d="M248.807 83.4703C248.542 83.787 248.437 84.1763 248.516 84.5554C248.594 84.9345 248.85 85.2734 249.228 85.5C249.416 85.6121 249.629 85.6926 249.855 85.7371C250.081 85.7815 250.316 85.7889 250.545 85.7589C250.775 85.729 250.995 85.6621 251.193 85.5623C251.391 85.4625 251.563 85.3316 251.699 85.1771C251.965 84.8603 252.069 84.471 251.991 84.0919C251.912 83.7129 251.656 83.374 251.278 83.1474C251.09 83.0353 250.877 82.9547 250.651 82.9103C250.425 82.8659 250.191 82.8584 249.961 82.8884C249.732 82.9184 249.512 82.9852 249.314 83.0851C249.116 83.1849 248.944 83.3158 248.807 83.4703V83.4703Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_85"
            opacity="0.2"
            d="M250.295 64.1422C250.027 64.4662 249.92 64.8629 249.999 65.2496C250.077 65.6362 250.334 65.9832 250.716 66.218C251.108 66.393 251.564 66.4443 251.998 66.3622C252.432 66.2801 252.815 66.0704 253.074 65.7726C253.333 65.4747 253.45 65.1093 253.404 64.7453C253.358 64.3812 253.151 64.0437 252.822 63.7963C252.43 63.5698 251.945 63.4791 251.472 63.5438C251 63.6086 250.577 63.8235 250.295 64.1422Z"
            fill="#42E8E0"
          />
          <path
            id="Vector_86"
            opacity="0.2"
            d="M256.922 64.3729C256.746 64.5816 256.677 64.8387 256.729 65.0889C256.782 65.3391 256.952 65.5624 257.202 65.7106C257.455 65.8187 257.748 65.8478 258.025 65.7925C258.302 65.7372 258.545 65.6013 258.71 65.4098C258.874 65.2184 258.949 64.9845 258.92 64.7514C258.89 64.5183 258.759 64.3019 258.55 64.1422C258.296 63.9977 257.983 63.9409 257.678 63.9841C257.374 64.0272 257.102 64.1668 256.922 64.3729V64.3729Z"
            fill="#42E8E0"
          />
          <g id="Kropki">
            <path
              id="k1"
              opacity="0.9"
              d="M225.446 147.566C227.229 147.566 228.675 146.379 228.675 144.914C228.675 143.449 227.229 142.262 225.446 142.262C223.663 142.262 222.217 143.449 222.217 144.914C222.217 146.379 223.663 147.566 225.446 147.566Z"
              fill="#42E8E0"
            />
            <path
              id="k4"
              opacity="0.9"
              d="M218.09 118.482C218.236 118.918 218.535 119.309 218.949 119.606C219.363 119.904 219.875 120.095 220.42 120.155C220.965 120.216 221.52 120.144 222.016 119.948C222.511 119.752 222.926 119.44 223.208 119.052C223.49 118.664 223.627 118.216 223.602 117.765C223.577 117.313 223.39 116.878 223.067 116.512C222.743 116.147 222.295 115.868 221.78 115.709C221.264 115.551 220.704 115.521 220.168 115.622C219.798 115.692 219.45 115.822 219.144 116.005C218.838 116.189 218.58 116.421 218.386 116.688C218.192 116.955 218.065 117.252 218.014 117.56C217.964 117.869 217.989 118.182 218.09 118.482V118.482Z"
              fill="#42E8E0"
            />
            <path
              id="k5"
              opacity="0.9"
              d="M225.446 117.421C227.229 117.421 228.675 116.234 228.675 114.769C228.675 113.304 227.229 112.116 225.446 112.116C223.663 112.116 222.217 113.304 222.217 114.769C222.217 116.234 223.663 117.421 225.446 117.421Z"
              fill="#42E8E0"
            />
            <path
              id="k8"
              opacity="0.9"
              d="M218.09 88.3369C218.236 88.7726 218.535 89.1636 218.949 89.4611C219.363 89.7586 219.875 89.9495 220.42 90.0102C220.965 90.0709 221.52 89.9987 222.016 89.8025C222.511 89.6063 222.926 89.2947 223.208 88.9066C223.49 88.5185 223.627 88.0709 223.602 87.6193C223.577 87.1678 223.39 86.7323 223.067 86.3669C222.743 86.0016 222.295 85.7224 221.78 85.5642C221.264 85.4059 220.704 85.3756 220.168 85.4769C219.798 85.5466 219.45 85.677 219.144 85.8602C218.838 86.0433 218.58 86.2755 218.386 86.5427C218.192 86.8099 218.065 87.1066 218.014 87.4149C217.964 87.7233 217.989 88.0369 218.09 88.3369V88.3369Z"
              fill="#42E8E0"
            />
            <path
              id="k2"
              opacity="0.9"
              d="M210.256 141.662C210.344 141.966 210.506 142.251 210.732 142.502C210.958 142.753 211.244 142.964 211.572 143.121C211.9 143.278 212.264 143.379 212.641 143.418C213.018 143.457 213.401 143.432 213.766 143.346C214.51 143.19 215.149 142.797 215.542 142.254C215.934 141.711 216.048 141.063 215.858 140.451C215.668 139.839 215.19 139.315 214.529 138.992C213.868 138.67 213.079 138.577 212.334 138.733C211.955 138.801 211.598 138.934 211.285 139.122C210.972 139.309 210.709 139.548 210.514 139.823C210.319 140.099 210.195 140.404 210.151 140.72C210.106 141.037 210.142 141.357 210.256 141.662Z"
              fill="#42E8E0"
            />
            <path
              id="k3"
              opacity="0.9"
              d="M218.567 128.907C218.698 129.335 219.03 129.704 219.49 129.931C219.951 130.158 220.503 130.225 221.024 130.118C221.545 130.011 221.994 129.738 222.27 129.36C222.546 128.981 222.629 128.528 222.498 128.1C222.434 127.888 222.319 127.688 222.161 127.513C222.002 127.337 221.803 127.189 221.575 127.077C221.347 126.964 221.094 126.89 220.831 126.857C220.568 126.825 220.3 126.836 220.041 126.889C219.783 126.942 219.54 127.036 219.327 127.166C219.113 127.297 218.932 127.46 218.796 127.647C218.659 127.835 218.568 128.042 218.529 128.259C218.49 128.475 218.503 128.695 218.567 128.907V128.907Z"
              fill="#42E8E0"
            />
            <path
              id="k9"
              opacity="0.9"
              d="M225.446 87.276C227.229 87.276 228.675 86.0885 228.675 84.6236C228.675 83.1587 227.229 81.9712 225.446 81.9712C223.663 81.9712 222.217 83.1587 222.217 84.6236C222.217 86.0885 223.663 87.276 225.446 87.276Z"
              fill="#42E8E0"
            />
            <path
              id="k10"
              opacity="0.9"
              d="M218.09 58.1917C218.28 58.8034 218.758 59.3281 219.419 59.6503C220.079 59.9725 220.869 60.066 221.614 59.91C222.358 59.754 222.997 59.3614 223.389 58.8185C223.782 58.2757 223.895 57.6271 223.705 57.0154C223.611 56.7125 223.446 56.4278 223.218 56.1775C222.99 55.9272 222.704 55.7163 222.377 55.5567C222.05 55.3972 221.687 55.2921 221.311 55.2476C220.934 55.203 220.55 55.2198 220.182 55.2971C219.813 55.3743 219.466 55.5104 219.162 55.6977C218.857 55.885 218.6 56.1197 218.406 56.3885C218.212 56.6573 218.084 56.9549 218.03 57.2643C217.975 57.5736 217.996 57.8888 218.09 58.1917V58.1917Z"
              fill="#42E8E0"
            />
            <path
              id="k6"
              opacity="0.9"
              d="M210.256 111.517C210.344 111.82 210.506 112.106 210.732 112.357C210.958 112.608 211.244 112.818 211.572 112.976C211.9 113.133 212.264 113.234 212.641 113.273C213.018 113.312 213.401 113.287 213.766 113.2C214.51 113.044 215.149 112.652 215.542 112.109C215.934 111.566 216.048 110.917 215.858 110.306C215.668 109.694 215.19 109.169 214.529 108.847C213.868 108.525 213.079 108.432 212.334 108.587C211.955 108.656 211.598 108.789 211.285 108.976C210.972 109.164 210.709 109.403 210.514 109.678C210.319 109.954 210.195 110.259 210.151 110.575C210.106 110.892 210.142 111.212 210.256 111.517Z"
              fill="#42E8E0"
            />
            <path
              id="k7"
              opacity="0.9"
              d="M218.567 98.762C218.663 99.0815 218.872 99.3704 219.169 99.5919C219.465 99.8134 219.835 99.9577 220.231 100.006C220.627 100.055 221.032 100.006 221.394 99.865C221.756 99.7243 222.059 99.4984 222.265 99.2159C222.471 98.9334 222.569 98.6071 222.549 98.2785C222.528 97.9498 222.389 97.6336 222.149 97.3699C221.909 97.1063 221.58 96.9071 221.202 96.7977C220.824 96.6883 220.415 96.6736 220.027 96.7554C219.77 96.8065 219.527 96.8993 219.314 97.0284C219.1 97.1574 218.92 97.3201 218.784 97.507C218.648 97.6938 218.559 97.901 218.522 98.1165C218.485 98.332 218.5 98.5514 218.567 98.762V98.762Z"
              fill="#42E8E0"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="55509.2"
        y1="-382181"
        x2="59826.1"
        y2="-372968"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="101616"
        y1="-362422"
        x2="97299.4"
        y2="-353211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="27838.2"
        y1="16032.1"
        x2="20383.5"
        y2="7412.31"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="180.549"
        y1="422.103"
        x2="336.158"
        y2="422.103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2B237C" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="228.113"
        y1="452.847"
        x2="101.328"
        y2="365.109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.1" stopColor="#1A1560" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.28" stopColor="#1F3372" />
        <stop offset="0.38" stopColor="#22447C" />
        <stop offset="0.57" stopColor="#2B7097" />
        <stop offset="0.83" stopColor="#39B7C2" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="30451.1"
        y1="9313.54"
        x2="23453"
        y2="-271.922"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="11171.2"
        y1="5931.21"
        x2="17204.1"
        y2="5931.21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2B237C" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="12204.2"
        y1="6567.34"
        x2="8685.87"
        y2="3319.21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.1" stopColor="#1A1560" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.28" stopColor="#1F3372" />
        <stop offset="0.38" stopColor="#22447C" />
        <stop offset="0.57" stopColor="#2B7097" />
        <stop offset="0.83" stopColor="#39B7C2" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="32030"
        y1="12199.6"
        x2="15587.1"
        y2="5071.61"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="13313.2"
        y1="21594.4"
        x2="5818.97"
        y2="18180.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="14942.9"
        y1="21770.5"
        x2="9142.39"
        y2="19600.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="14035.3"
        y1="7881.64"
        x2="8315.07"
        y2="6502.92"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#180D5B" />
        <stop offset="0.09" stopColor="#1A1660" />
        <stop offset="0.25" stopColor="#1E2D6E" />
        <stop offset="0.44" stopColor="#255385" />
        <stop offset="0.66" stopColor="#2F87A5" />
        <stop offset="0.9" stopColor="#3CCACE" />
        <stop offset="1" stopColor="#42E8E0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="216.742"
        y1="185.9"
        x2="216.742"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1" stopColor="#3CCACE" />
        <stop offset="0.34" stopColor="#2F87A5" />
        <stop offset="0.56" stopColor="#255385" />
        <stop offset="0.75" stopColor="#1E2D6E" />
        <stop offset="0.91" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="17215.4"
        y1="14983.5"
        x2="17215.4"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#42E8E0" />
        <stop offset="0.1" stopColor="#3CCACE" />
        <stop offset="0.34" stopColor="#2F87A5" />
        <stop offset="0.56" stopColor="#255385" />
        <stop offset="0.75" stopColor="#1E2D6E" />
        <stop offset="0.91" stopColor="#1A1660" />
        <stop offset="1" stopColor="#180D5B" />
      </linearGradient>
      <clipPath id="clip0">
        <rect
          width="639"
          height="555"
          fill="white"
          transform="translate(-102)"
        />
      </clipPath>
    </defs>
  </SvgIconStyles>
)

const BeginHereSection = () => {
  const width = useWindowSize()
  return (
    <WhyStyles>
      {width >= 1118 && <IconStyles src={BlocksIcon} alt="" />}
      {/* <InvestorsIcon /> */}
      <Wrapper>
        {/* <img src={investorsIcon} alt="" /> */}
        <h2>Oferta</h2>
        <h3>Zacznij tutaj</h3>
        <BeginCards />
      </Wrapper>
    </WhyStyles>
  )
}

export default BeginHereSection
